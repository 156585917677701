import React, { useRef } from "react";

interface IFormInputProps {
	label: string;
	value: string;
	onChange: (event: React.ChangeEvent<any>) => void;
	onKeyDown?: (event: React.KeyboardEvent<any>) => void;
	onBlur?: (event: any) => void;
	type?: string;
	myRef?: any;
	onFocus?: any;
	cols?: number;
	rows?: number;
	placeholder?: string;
	resize?: boolean;
	max?: number;
	min?: number;
	required?: boolean;
	noLabel?: boolean;
	disabled?: boolean;
}

const FormInput = ({
	label,
	value,
	type,
	onChange,
	onKeyDown,
	onBlur,
	myRef,
	onFocus,
	cols,
	rows,
	placeholder,
	resize,
	max,
	min,
	required,
	noLabel,
	disabled,
}: IFormInputProps) => {
	const input = useRef<HTMLInputElement>(null);
	const textArea = useRef<HTMLTextAreaElement>(null);
	if (type === "textarea") {
		return (
			<div>
				<label
					htmlFor={label}
					className="block bebas text-lg font-medium text-neutral-50"
				>
					{label}
					{required && <span>*</span>}
				</label>
				<textarea
					style={{ resize: "none", whiteSpace: "pre-line" }}
					value={value}
					onFocus={onFocus}
					onBlur={onBlur}
					ref={myRef ? myRef : textArea}
					cols={cols || 4}
					rows={rows || 5}
					id={label}
					wrap="hard"
					onChange={onChange}
					onKeyDown={onKeyDown}
					placeholder={placeholder || ""}
					className={`${
						!resize ? "no-resize" : ""
					} text-sm rounded-lg block w-full py-2.5 px-4 bg-neutral-800 border-neutral-700 border-solid border text-neutral-50`}
					autoComplete="off"
				/>
			</div>
		);
	}
	return (
		<div>
			{noLabel ? null : (
				<label
					htmlFor={label}
					className="block bebas text-lg font-medium text-neutral-50"
				>
					{label} {min ? `(Min: ${min})` : ""} {max ? `(Max: ${max})` : ""}
					{required && <span>*</span>}
				</label>
			)}
			<input
				type={type || "text"}
				value={value}
				onChange={onChange}
				disabled={!!disabled}
				ref={myRef ? myRef : input}
				onKeyDown={(event) => {
					if (type === "number") {
						const keyCode = event.key;
						const BCKSPACE = "Backspace";
						const TAB = "Tab";
						const LEFT = "ArrowLeft";
						const RIGHT = "ArrowRight";
						const UP = "ArrowUp";
						const DOWN = "ArrowDown";

						const allowedKeys = [BCKSPACE, TAB, LEFT, RIGHT, UP, DOWN];

						// if not a number then dont accept keyboard input
						if (!/^\d+$/.test(keyCode) && !allowedKeys.includes(keyCode))
							event.preventDefault();

						// if exceeds max then set to return
						// if (max && parseInt(value) > max) {
						// 	event.preventDefault();
						// }

						// if subceeds min then set return
						// if (min && parseInt(value) < min) {
						// 	event.preventDefault();
						// }
					}

					return onKeyDown ? onKeyDown(event) : undefined;
				}}
				id={label}
				onBlur={onBlur}
				className=" text-sm rounded-lg block w-full py-4 px-4 bg-neutral-800 border-neutral-700 border-solid border text-neutral-50 "
				placeholder={placeholder || ""}
				required
			/>
		</div>
	);
};

export default FormInput;
