import React, { Dispatch, FC, SetStateAction } from "react";
import SpotifyLoginButton from "../../components/global/SpotifyLoginButton";

import { GoogleLoginButton } from "react-social-login-buttons";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface ISocialMediaLoginsProps {
	logIn: boolean;
	spotifyMiddleStage: boolean;
	setSpotifyMiddleStage: Dispatch<SetStateAction<boolean>>;
}

const SocialMediaLogins: FC<ISocialMediaLoginsProps> = ({}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname, search } = useLocation();

	return (
		<div className="loginModal px-5 pt-2.5 pb-5 rounded-lg socials w-full">
			<GoogleLoginButton
				style={{ borderRadius: "10px" }}
				text="Google"
				onClick={() => {
					localStorage.setItem(
						"SOCIAL_MEDIA_LOGIN_REDIRECT_URI",
						pathname + search
					);
					setTimeout(() => {
						window.location.href = `https://www.wvey.co.uk/api/v1/auth/google`;
					}, 0);
				}}
			/>

			{/* <TwitterLoginButton
				style={{ borderRadius: "10px" }}
				onClick={() => {
					localStorage.setItem(
						"SOCIAL_MEDIA_LOGIN_REDIRECT_URI",
						pathname + search
					);
					setTimeout(() => {
						window.location.href = `http://localhost:8000/api/v1/auth/twitter`;
					}, 0);
				}}
			/>

			<InstagramLoginButton
				style={{ borderRadius: "10px" }}
				onClick={() => {
					localStorage.setItem("SPOTIFY_REDIRECT_URI", pathname + search);
					setTimeout(() => {
						window.location.href = `http://localhost:8000/api/v1/auth/instagram`;
					}, 0);
				}}
			/> */}

			<SpotifyLoginButton
				onClick={() => {
					localStorage.setItem(
						"SOCIAL_MEDIA_LOGIN_REDIRECT_URI",
						pathname + search
					);
					setTimeout(() => {
						window.location.href = `http://www.wvey.co.uk/api/v1/auth/spotify`;
					}, 0);
				}}
			>
				Spotify
			</SpotifyLoginButton>
		</div>
	);
};

export default SocialMediaLogins;
