import { IBooking } from "@Eassame/wvey-types";

export const initialState: {
	complete: IBooking[];
} = {
	complete: [],
};

const handleAddingPayload = (state: IBooking[], payload: IBooking) => {
	if (state.some((booking) => booking._id === payload._id)) {
		return state;
	} else {
		return [...state, payload];
	}
};

const bookingReducer = (
	state = initialState,
	{
		type,
		payload,
	}: {
		type: string;
		payload: any;
	}
) => {
	switch (type) {
		case "GET_BOOKING":
			console.log("payload: ", payload);
			return {
				...state,
				complete: handleAddingPayload(state.complete, payload),
			};
		case "CLEAR_STATE_AFTER_LOG_OUT":
			return { complete: [] };
		default:
			return state;
	}
};

export default bookingReducer;
