import React, { Children } from "react";
import "./Button.scss";
import { createButton } from "react-social-login-buttons";

const SpotifyLoginButton = ({ children, ...props }: any) => {
	const config = {
		text: "Log in with Facebook",
		icon: "Spotify",
		style: { background: "#1C9747", borderRadius: "10px" },
		activeStyle: { background: "#106E31" },
		className: "spotifyButton",
	};

	const SpotifyLoginButton = createButton(config);

	return (
		<SpotifyLoginButton {...props}>
			<>
				<img src={"/static/spotify-icon.png"} />
				{children}
			</>
		</SpotifyLoginButton>
	);
};

export default SpotifyLoginButton;
