import { ICheckout } from "@Eassame/wvey-types";

export const initialState: ICheckout = {
	numberOfTickets: undefined,
	eventId: undefined,
	ticketVersion: undefined,
	ticketPrice: undefined,
};

const checkoutReducer = (
	state = initialState,
	{
		type,
		payload,
	}: {
		type: string;
		payload: any;
	}
) => {
	switch (type) {
		case "ADD_TO_BASKET":
			const basketData = JSON.stringify(payload);
			localStorage.setItem("basket", basketData);
			return {
				...state,
				...payload,
			};
		case "CLEAR_BASKET":
			localStorage.removeItem("basket");
			return {
				numberOfTickets: undefined,
				eventId: undefined,
				ticketVersion: undefined,
			};
		default:
			return state;
	}
};

export default checkoutReducer;
