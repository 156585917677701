import { IEvent, IMapEvent, IBooking } from "@Eassame/wvey-types";
import { isAString } from "../../helpers";

interface IInitialState {
	events: IEvent[] | undefined;
	pages: number | undefined;
	hostedEvents: IEvent[] | undefined;
	savedEvents: IEvent[] | undefined;
	requestedEvents: IEvent[] | undefined;
	approvedEvents: IEvent[] | undefined;
	mapEvents: IMapEvent[] | undefined;
	eventLoading: boolean;
	eventsLoading: boolean;
}

export const initialState: IInitialState = {
	events: undefined,
	pages: undefined,
	hostedEvents: undefined,
	savedEvents: undefined,
	requestedEvents: undefined,
	approvedEvents: undefined,
	mapEvents: undefined,
	eventLoading: true,
	eventsLoading: true,
};

const handleEvents = (
	state: IInitialState,
	payload: IEvent,
	isForMap: boolean
) => {
	const updatedPayload = isForMap ? { ...payload, isForMap: true } : payload;
	if (state && state.events && state?.events.length > 0) {
		if (state.events.some((event) => event._id === updatedPayload?._id)) {
			return isForMap
				? state.events
				: state.events.map((event) => {
						if (event._id === updatedPayload?._id) {
							return updatedPayload;
						}
						return event;
				  });
		}
		return [...state.events, updatedPayload];
	}
	return [updatedPayload];
};

const eventsReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case "GET_EVENTS":
			return {
				...state,
				events: action.payload.result,
				pages: action.payload.pages,
				eventsLoading: false,
			};
		case "GETTING_EVENTS":
			return {
				...state,
				eventsLoading: action.payload ?? true,
			};
		case "GET_EVENT":
			return {
				...state,
				events: handleEvents(state, action.payload, action.isForMap),
				eventLoading: false,
			};
		case "GETTING_EVENT":
			return {
				...state,
				eventLoading: action.payload ?? true,
			};
		case "GET_SAVED_EVENTS":
			const { savedEvents } = action.payload;
			const sortedSavedEvents = savedEvents.sort(
				(a: IEvent, b: IEvent) => a.date < b.date
			);
			return {
				...state,
				savedEvents: sortedSavedEvents,
			};
		case "UPDATE_SAVED_EVENTS_ON_SAVE":
			const savedEvent: IEvent = action.payload;
			const sorted = state.savedEvents
				? [savedEvent, ...state.savedEvents]
				: [savedEvent];
			return {
				...state,
				savedEvents: sorted,
			};

		/////////////THIS ISN'T USED...REMOVE///////////
		case "UPDATE_REQUESTED_EVENTS": {
			const { requestedEvents } = action.payload;
			return {
				...state,
				requestedEvents,
			};
		}
		////////////////////////////////////////////////
		case "UPDATE_REQUESTED_EVENTS_ON_REQUEST": {
			const requestedEvent = action.payload;
			return {
				...state,
				requestedEvents: state.requestedEvents
					? [requestedEvent, ...state.requestedEvents]
					: [requestedEvent],
			};
		}
		case "GET_REQUESTED_EVENTS":
			const { requestedEvents } = action.payload;

			return {
				...state,
				requestedEvents,
			};
		case "GET_APPROVED_EVENTS":
			const { approvedEvents } = action.payload;

			return {
				...state,
				approvedEvents,
			};
		case "GET_HOSTED_EVENTS":
			const { hostedEvents } = action.payload;
			const sortedHostEvents = hostedEvents.sort(
				(a: IEvent, b: IEvent) => a.createdOn < b.createdOn
			);
			return {
				...state,
				hostedEvents: sortedHostEvents,
			};
		case "UPDATE_HOSTED_EVENT":
			{
				const { event } = action.payload;

				return {
					...state,
					hostedEvents:
						state?.hostedEvents && state?.hostedEvents?.length > 0
							? state.hostedEvents?.map((hostedEvent) =>
									hostedEvent._id === event._id ? event : hostedEvent
							  )
							: [event],
				};
			}
			break;
		case "ADD_EVENT_TO_HOSTED_EVENTS":
			const { event } = action.payload;
			return {
				...state,
				hostedEvents: [
					event,
					...(state?.hostedEvents?.filter(
						(hostedEvent) => hostedEvent._id !== event._id
					) || []),
				],
			};
		case "GET_EVENT_DETAILS_FOR_HOSTED_EVENT":
			const hostedEvent = action.payload as IEvent;
			console.log("hostedEvent: ", hostedEvent);
			return {
				...state,
				hostedEvents:
					state?.hostedEvents && state?.hostedEvents?.length > 0
						? state?.hostedEvents?.map((event) =>
								hostedEvent._id === event._id ? hostedEvent : event
						  )
						: [hostedEvent],
			};
		case "REMOVE_SAVED_EVENTS":
			return {
				...state,
				savedEvents: state.savedEvents
					? state.savedEvents.filter((event) => event._id !== action.payload)
					: undefined,
			};
		case "GET_MAP_EVENTS":
			return {
				...state,
				mapEvents: action.payload,
			};
		case "UPDATE_EVENT_WITH_USER_WHO_SAVED":
			return {
				...state,
				events: state.events?.map((event: IEvent) => {
					if (event._id === action.eventUuid) {
						const usersWhoSaved = event?.usersWhoHaveSaved;
						return {
							...event,
							usersWhoHaveSaved:
								usersWhoSaved &&
								!usersWhoSaved.some(
									//@ts-ignore
									(userWhoSaved) => userWhoSaved._id === action.payload._id
								)
									? [...usersWhoSaved, action.payload]
									: usersWhoSaved,
						};
					}
					return event;
				}),
			};
		case "CLEAR_EVENTS":
			return {
				...state,
				events: undefined,
				mapEvents: undefined,
				page: 1,
			};
		default:
			return state;
	}
};

export default eventsReducer;
