import React, { FC, useEffect, useState } from "react";
import { useTypedSelector } from "../../../redux/reducers/rootReducer";
import { IRoute } from "../../../Types";
import { logOut } from "../../../redux/actions/userActions";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../styles/globalComponents.scss";
import { ICheckout } from "@Eassame/wvey-types";
import { setDarkMap, showLoginModal } from "../../../redux/actions/viewActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { setDarkMode } from "../../../redux/actions/viewActions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import NavbarSearch from "../../global/Navbar/NavbarSearch";

interface INavbarProps {
	routeHandler: IRoute[];
	loading: boolean;
}

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props: MenuProps) => (
	<Menu
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));

export const Navbar: FC<INavbarProps> = ({ routeHandler, loading }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { pathname: currentPathName, search } = location;

	const darkMap = useTypedSelector((state) => state.view?.darkMap);
	const loggedInUser = useTypedSelector(
		(state) => state.admin?.loggedInUser.user
	);
	const name = loggedInUser?.firstName || loggedInUser?.fullName;
	const darkMode = useTypedSelector((state) => state.view.darkMode);
	const checkout = useTypedSelector<ICheckout>((state) => state?.checkout);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isHomepage = currentPathName === "/";
	const isResultsPage = currentPathName.includes("/events/");
	const isLoginPage = currentPathName.includes("/login");

	const [searchIsActive, setSearchIsActive] = useState<boolean>(false);
	const [scrolledDown, setScrolledDown] = useState<boolean>(false);
	const [showAvatar, setShowAvatar] = useState<boolean>(
		loggedInUser && loggedInUser.imageUrl
	);

	const handleWindowScroll = (event: Event) => {
		if (isResultsPage) return;

		const scrollPositionToSetState = 40;
		if (window.scrollY < scrollPositionToSetState) {
			setScrolledDown(false);
		} else {
			setScrolledDown(true);
		}
	};

	useEffect(() => {
		setScrolledDown(false);
		window.scrollTo({
			top: 0,
			left: 0,
			// behavior: "smooth",
		});
	}, [currentPathName]);

	useEffect(() => {
		setSearchIsActive(false);
	}, [search]);

	useEffect(() => {
		window.addEventListener("scroll", handleWindowScroll);
	}, []);

	useEffect(() => {
		setAnchorEl(null);
		setShowAvatar(loggedInUser && loggedInUser.imageUrl);
	}, [loggedInUser]);

	const dispatch = useDispatch();

	const handleUnderline = (pathnameInput: string) => {
		return pathnameInput === currentPathName ? "current" : "notCurrent";
	};

	const handleSignOut = () => {
		dispatch(logOut());
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!loggedInUser) {
			dispatch(showLoginModal(true));
			handleClose();
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const currentRoute = routeHandler.find(
		(route) => route.path === currentPathName
	);

	if (currentRoute?.hideNavBarOnPage) {
		return null;
	}

	const navBarClassname = [
		"navBar z-20",
		// withBorder ? "border" : "",
		isHomepage || isLoginPage ? "homepage " : "",
		scrolledDown && !isResultsPage ? "active" : "",
	].join(" ");

	const navSearchClassname = ["navSearch", searchIsActive ? "active" : ""].join(
		" "
	);

	const nameForInitial = name;

	return (
		// <div className="globalNavbar">
		<div className={navBarClassname}>
			<ul className={!isResultsPage ? "maxWidth" : ""}>
				<li className={handleUnderline("/")}>
					<button
						onDoubleClick={() => dispatch(setDarkMap(!darkMap))}
						onClick={() => navigate("/")}
						className="homeLogo"
					>
						<img
							src={`/static/wvey-white.svg`}
							alt="wvey logo in black"
							height="40"
						/>
					</button>
				</li>
				{routeHandler.map((route) => {
					if (route.navBarTitle) {
						return (
							<li
								key={route.navBarTitle}
								className={handleUnderline(route.path)}
							>
								<Link to={route.path} className="bebas">
									{route.navBarTitle}
								</Link>
							</li>
						);
					}
				})}
				<li className={navSearchClassname}>
					<div
						className="background"
						onClick={() => setSearchIsActive(false)}
					/>
					<NavbarSearch
						searchIsActive={searchIsActive}
						setSearchIsActive={setSearchIsActive}
					/>
				</li>
				<li className="notCurrent">
					<div>
						<button
							className="iconButton"
							aria-controls="profile-menu"
							onClick={handleClick}
						>
							{loggedInUser ? (
								showAvatar ? (
									<img
										className="avatar"
										src={loggedInUser.imageUrl}
										alt="profile avatar"
										onError={({ currentTarget }) => {
											return (
												<Avatar>
													{nameForInitial ? nameForInitial[0] : "?"}
												</Avatar>
											);
										}}
									/>
								) : (
									<Avatar>{nameForInitial ? nameForInitial[0] : "?"}</Avatar>
								)
							) : (
								<AccountCircleIcon fontSize="large" />
							)}
						</button>
						{loggedInUser && (
							<StyledMenu
								id="profile-menu"
								anchorEl={anchorEl}
								// keepMounted
								open={!!anchorEl}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => {
										navigate("/profile");
										handleClose();
									}}
								>
									Profile
								</MenuItem>

								<MenuItem
									onClick={() => {
										navigate("/host");
										handleClose();
									}}
								>
									{loggedInUser.hostedEvents.length > 0
										? "Hosted Events"
										: "Create an Event"}
								</MenuItem>

								<MenuItem
									onClick={() => {
										navigate("/account");
										handleClose();
									}}
								>
									Account
								</MenuItem>
								<MenuItem onClick={handleSignOut}>Logout</MenuItem>
							</StyledMenu>
						)}
					</div>
				</li>
			</ul>
		</div>
		// </div>
	);
};

export default Navbar;
