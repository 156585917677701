import { genre, IBooking, IEvent, IUser } from "@Eassame/wvey-types";
import { showLoginModal } from "../redux/actions/viewActions";
import { addSavedEvent, removeSavedEvent } from "../redux/actions/userActions";
import { AppDispatch } from "../ConsumerAppEntry";
import moment from "moment";

export const urlParameterReplacer = (
	currentUrl: string,
	objectOfChangingQueries: { param: string; value: string | null | undefined }[]
) => {
	const paramsSection = currentUrl.split("?");
	const currentParams = paramsSection[1].split("&");
	const paramValueObject: { [key: string]: string } = {};
	currentParams.forEach((paramString) => {
		const splitParamString = paramString.split("=");
		paramValueObject[splitParamString[0]] = splitParamString[1];
	});

	objectOfChangingQueries.forEach((selector) => {
		if (!selector.value || selector.value === "") {
			delete paramValueObject[selector.param];
			return;
		}
		paramValueObject[selector.param] = selector.value;
	});

	var finalStringOfParams = Object.keys(paramValueObject)
		.map((key) => `${key}=${paramValueObject[key]}`)
		.join("&");
	return paramsSection[0] + "?" + finalStringOfParams;
};

export const isAString = (value: any | null) => {
	if (value === null) return false;
	return typeof value === "string";
};

export const isEmptyObject = (empty: any) =>
	Object.keys(empty).length === 0 && empty.constructor === Object;

export const generateRandomString = (length: number) => {
	let result = "";
	let characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const isNumber = (val: string) => {
	return /^\d+$/.test(val);
};

export const isEmail = (val: string) => {
	return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
		val
	);
};

export const formatCurrency = (amount: number) => {
	return "£" + (amount / 100).toFixed(2);
};

export const calculateServiceCharge = (
	amount: number,
	percentage: number = 10
) => {
	const amountWithServiceAdded = amount * (100 / (100 - percentage));
	const serviceCharge = amountWithServiceAdded - amount;
	return { serviceCharge, amountWithServiceAdded };
};

export const splitStringAtIndex = (value: string, index: number) => {
	return [value.substring(0, index), value.substring(index)];
};

export const stringToNumber = (value: string): number =>
	parseInt(value.replace(/\./g, ""), 10);

interface IWindowWithGapi extends Window {
	gapi?: any;
}
export const googleLogoutHandler = () => {
	const currentWindow = window as IWindowWithGapi;
	if (currentWindow.gapi) {
		const auth2 = currentWindow.gapi.auth2.getAuthInstance();
		if (auth2 != null) {
			auth2.signOut().then(auth2.disconnect());
		}
	}
};

export const timeSlots = Array.from(new Array(24.5 * 2)).map(
	(_, index) =>
		`${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
			index % 2 === 0 ? "00" : "30"
		}`
);

export const setHandleAddingToSavedEvents = (
	loggedInUser: IUser,
	dispatch: AppDispatch,
	notLoggedInCallback?: (eventUuid: string) => void
) => {
	return (eventUuid: string) => {
		if (loggedInUser) {
			const eventAlreadySaved = !loggedInUser.savedEvents.includes(eventUuid);
			if (eventAlreadySaved) {
				dispatch(addSavedEvent(eventUuid, loggedInUser._id));
			} else {
				dispatch(removeSavedEvent(eventUuid, loggedInUser._id));
			}
		} else {
			dispatch(showLoginModal(true));
			notLoggedInCallback && notLoggedInCallback(eventUuid);
		}
	};
};

export const eventIsCurrentlyRunning = (event: IEvent) =>
	moment(Date.now()).unix() > moment(event.date).unix() &&
	moment(Date.now()).unix() < moment(event.expiresOn).unix();

// export const debounce = (func: () => any, delay: number) => {
// 	let timeout: NodeJS.Timeout;
// 	return function executedFunction(...args: any[]) {
// 		const later = () => {
// 			clearTimeout(timeout);

// 			func(...args);
// 		};

// 		clearTimeout(timeout);
// 		timeout = setTimeout(later, delay);
// 	};
// };

export const isBooking = (bookingOrEvent: IBooking | IEvent) =>
	(bookingOrEvent as IBooking).currentTransactionUuid ||
	(bookingOrEvent as IBooking)?.numberOfTicketsPurchased;

export const genres = [
	{
		value: "acoustic",
		label: "Acoustic",
	},
	{
		value: "afrobeat",
		label: "Afrobeat",
	},
	{
		value: "arabic",
		label: "Arabic",
	},
	{
		value: "bass",
		label: "Bass",
	},
	{
		value: "blues",
		label: "Blues",
	},
	{
		value: "bossa nova",
		label: "Bossa Nova",
	},
	{
		value: "classic rock",
		label: "Classic Rock",
	},
	{
		value: "country",
		label: "Country",
	},
	{
		value: "dance",
		label: "Dance",
	},
	{
		value: "dancehall",
		label: "Dancehall",
	},
	{
		value: "dnb",
		label: "DnB",
	},
	{
		value: "drill",
		label: "Drill",
	},
	{
		value: "electronic",
		label: "Electronic",
	},
	{
		value: "funk",
		label: "Funk",
	},
	{
		value: "hiphop",
		label: "Hiphop",
	},
	{
		value: "house",
		label: "House",
	},
	{
		value: "jazz",
		label: "Jazz",
	},
	{
		value: "jungle",
		label: "Jungle",
	},
	{
		value: "mainstream",
		label: "Mainstream",
	},
	{
		value: "pop",
		label: "Pop",
	},
	{
		value: "psychedelic rock",
		label: "Psychedelic Rock",
	},
	{
		value: "reggae",
		label: "Reggae",
	},
	{
		value: "reggaeton",
		label: "Reggaeton",
	},
	{
		value: "rnb",
		label: "RnB",
	},
	{
		value: "samba",
		label: "Samba",
	},
	{
		value: "soul",
		label: "Soul",
	},
	{
		value: "techno",
		label: "Techno",
	},
];

export const isTouchDevice = () => {
	return (
		"ontouchstart" in window ||
		navigator.maxTouchPoints > 0 ||
		//@ts-ignore
		navigator.msMaxTouchPoints > 0
	);
};

// if you want other people to access wvey from local network change package.json to 192.... and
// set PROXY to 192... Also change PROXY value in server and add http://192....:3000/login to
// Redirect URIs on spotify
